import React from 'react';
import {Helmet} from 'react-helmet-async';
import {Link as RouterLink} from 'react-router-dom';
import {composePageTitle} from './Common';
import Footer from './components/Footer';
import Header from './components/Header';
import Application from './config/application';
import {PageUrl} from './Constants';

const Destinations = () => {
return (
<div id="main-wrapper">
  <Helmet title={composePageTitle('Destinations')} />
  <Header />
  <section className="page-header page-header-dark bg-secondary">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-md-8">
          <h1>Destinations</h1>
        </div>
        <div className="col-md-4">
          <ul className="breadcrumb justify-content-start justify-content-md-end mb-0">
            <li>
              <RouterLink to={PageUrl.HOME}>Home</RouterLink>
            </li>
            <li className="active">Destinations </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  <div id="content">
    <div className="container">
      <div className="bg-white shadow-md rounded p-4">
        <h2>
          Avail The Perfect Destination By Booking Cheap Flight Ticket
        </h2>
        <p>
          Welcome to {Application.ORG_DISPLAY_NAME}! As your premier travel agency, we specialize in offering seamless booking solutions for all major US airlines. Operating as a trusted third-party consolidator, we take pride in curating exceptional flight deals and extraordinary offers. Our user-friendly platform ensures effortless reservations, catering to both business and leisure travelers. With a strong focus on affordability and convenience, we bring you unmatched savings on air travel. Explore a diverse range of destinations through {Application.ORG_DISPLAY_NAME} and embark on unforgettable journeys. Let our remarkable flight offers transform your travel dreams into reality. Your next adventure begins with us – your dedicated partner in travel excellence.

        </p>

      </div>
    </div>
    <section className="section">
      <div className="container">
        <h2 className="text-9 fw-500 text-center">Popular Destinations</h2>
        <p className="lead text-center mb-4">
          World's leading Flight Booking website.
        </p>
        <div className="row g-4 banner">
          <div className="col-md-8">
            <div className="item rounded h-100">
              <div className="caption text-center">
                <h2 className="text-7">Dubai</h2>
              </div>
              <div className="banner-mask"></div>
              <img className="img-fluid h-100" src={`./images/dest_images/7.jpg`} alt="Dubai" />
            </div>
          </div>
          <div className="col-md-4">
            <div className="row g-4">
              <div className="col-12">
                <div className="item rounded">
                  <div className="caption text-center">
                    <h2>New York</h2>
                  </div> 
                  <div className="banner-mask"></div>
                  <img className="img-fluid" src="images/Calling-Banner.png" alt="New York" />
                </div>
              </div>
              <div className="col-12 mt-lg-3">
                <div className="item rounded">
                  <div className="caption text-center">
                    <h2>Las Vegas</h2>
                  </div>
                  <div className="banner-mask"></div>
                  <img className="img-fluid" src={`./images/dest_images/9.jpg`} alt="Las Vegas" />{' '}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <Footer />
</div>
);
};

export default Destinations;