import React from 'react';
import {Switch, Route, useHistory} from 'react-router-dom';
import {ErrorBoundary} from 'react-error-boundary';
import {PageUrl, QueryKeys} from './Constants';
import Home from './Home';
import Listing from './Listing';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import RootErrorFallback, {rootErrorHandler} from './ErrorBoundary';
import Booking from './Booking';
import Payment from './Payment';
import Thankyou from './Thankyou';
import Terms from './Terms';
import Privacy from './Privacy';
import Destinations from './Destinations';
import About from './About';
import Contact from './Contact';
import CancelRefund from './CancelRefund';
import Consent from './Consent';
import { useQuery } from 'react-query';
import { airlinesFetch, airportsFetch } from './api/fetches';

function App() {
  const history = useHistory();

  // //Load the data that's essential for the app to function regardless of the page
  // const {data: airports} = useQuery([QueryKeys.AIRPORTS], airportsFetch, {
  //   staleTime: 60 * 60 * 1000,
  //   refetchOnWindowFocus: false,
  // });

  // const {data: airlines} = useQuery([QueryKeys.AIRLINES], airlinesFetch, {
  //   staleTime: 60 * 60 * 1000,
  //   refetchOnWindowFocus: false,
  // });

  // if (!(airports && airlines)) {
  //   // For now just don't render anything and let a blank page appears until this has loaded.
  //   // Given this is not going to happen often, we should be good.
  //   // TODO: later we can apply error handling so that if for some reason these are not loaded,
  //   // show an error to the user.
  //   return null;
  // }
  
  return (
    <ErrorBoundary
      FallbackComponent={RootErrorFallback}
      onReset={() => history.push(PageUrl.HOME)}
      onError={rootErrorHandler}>
      <Switch>
        <Route path={PageUrl.THANK_YOU}>
          <Thankyou />
        </Route>
        <Route path={PageUrl.PAYMENT}>
          <Payment />
        </Route>
        <Route path={PageUrl.BOOKING}>
          <Booking />
        </Route>
        <Route path={PageUrl.LISTING}>
          <Listing />
        </Route>
        <Route exact path={PageUrl.HOME}>
          <Home />
        </Route>
        <Route exact path={PageUrl.TERMS}>
          <Terms />
        </Route>
        <Route exact path={PageUrl.PRIVACY}>
          <Privacy />
        </Route>
        <Route exact path={PageUrl.DESTINATION}>
          <Destinations />
        </Route>
        <Route exact path={PageUrl.ABOUT}>
          <About />
        </Route>
        <Route exact path={PageUrl.CONTACT}>
          <Contact />
        </Route>
        <Route exact path={PageUrl.CANCEL_REFUND_POLICY}>
          <CancelRefund />
        </Route>
        <Route exact path={PageUrl.Consent}>
          <Consent />
        </Route>
        <Route path="*">
          {/* Keep this in the end of switch */}
          <Home />
        </Route>
      </Switch>
    </ErrorBoundary>
  );
}

export default App;
