const Application = {
  ORG: 'flights-travels',
  ORG_DISPLAY_NAME: 'Flights-Travels',
  ORG_ADD_LINE1: '14683 Midway Rd, Addison,',
  ORG_ADD_LINE2: 'TX 75001, USA',
  STORAGE_HOST: 'https://storage.googleapis.com',
  PRODUCTION_API_BASE_URL: 'https://myfaresinfo.com/alpha',
  LOCAL_API_BASE_URL: 'http://localhost:8080/alpha',
  STORAGE_URL_STATIC_ASSETS_PATH: 'https://storage.googleapis.com/sfc-static',
  DOMAIN: 'flights-travels.com',
  SUPPORT_PHONE: '+1 (844) 674-1415', 
  CONTACT_EMAIL: 'flighttravelsteam@gmail.com',
  MAP_ADDRESS: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3348.0845675249784!2d-96.84435881315544!3d32.94877660711192!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864c26cdf1f060e3%3A0x55b4c55c9e382e9c!2s14683%20Midway%20Rd%2C%20Addison%2C%20TX%2075001%2C%20USA!5e0!3m2!1sen!2sin!4v1692402107457!5m2!1sen!2sin',
  CALL_NOW_POPUP_HEADING_TEXT: 'Call now for Booking, Changes and Cancellation',
  CALL_NOW_POPUP_SAVE_PRICE: 30,
  CALL_NOW_POPUP_SAME_PAGE_TIMEOUT_SEC: 30,
  DISCLAIMER: `Looking for a comprehensive travel experience at budget-friendly rates? Flights Travels is the quickest and the easiest way to book flights online at discounted rates. Since the time we came into inception, we lead the entire market of flight booking. Flight Travels offers you the best control over the travel plans. `,
  GA_ID: '',
};
export default Application;
