import React from 'react';
import {Helmet} from 'react-helmet-async';
import {Link as RouterLink} from 'react-router-dom';
import {composePageTitle} from './Common';
import Footer from './components/Footer';
import Header from './components/Header';
import Application from './config/application';
import {PageUrl} from './Constants';

const Consent = () => {
    return (
    <div id="main-wrapper">
     <Helmet title={composePageTitle('TCPA Consent Disclaimer')} />
     <Header />
     <section className="page-header page-header-dark bg-secondary">
      <div className="container">
       <div className="row align-items-center">
        <div className="col-md-8">
         <h1>TCPA Consent </h1>
        </div>
        <div className="col-md-4">
         <ul className="breadcrumb justify-content-start justify-content-md-end mb-0">
          <li>
           <RouterLink to={PageUrl.HOME}>Home</RouterLink>
          </li>
          <li className="active">TCPA Consent</li>
         </ul>
        </div>
       </div>
      </div>
     </section>
     <div id="content">
      <div className="container">
       <div className="bg-white shadow-md rounded p-4">
    
        <p>{Application.ORG_DISPLAY_NAME} TCPA Consent Disclaimer for Existing Customers:</p>
        <p>By providing your phone number and by replying to this email, you acknowledge and agree to the following:</p>
        <ol>
         <li><b>Existing Customer Relationship:</b> You confirm that you are an existing customer of {Application.ORG_DISPLAY_NAME} and have an ongoing business relationship with us.</li>
         <li><b>Express Consent for Communication:</b> You expressly consent to {Application.ORG_DISPLAY_NAME} and its authorized representatives contacting you at the provided phone number, including calls and text messages (SMS), using an automated dialing system or pre-recorded voice, for service updates, support, account-related information, and other transactional or informational purposes related to your existing services or products.</li>
         <li><b>Phone Number Ownership:</b> You affirm that you are the owner of the phone number provided or have the legal authority to provide consent for its use. We should be informed immediately if you do not own the phone number or you are not authorised to use this number.</li>
         <li><b>Opt-Out Provision:</b> You understand that you are not required to provide this consent as a condition of continuing your business relationship with {Application.ORG_DISPLAY_NAME}. You may withdraw your consent for non-essential communications at any time by sending a written request to {Application.CONTACT_EMAIL} or by using the opt-out mechanism provided in our communications.</li>
         <li><b>No Guarantee of Privacy:</b> While {Application.ORG_DISPLAY_NAME} takes appropriate measures to protect your personal information, you acknowledge that communication over phone calls and text messages is not entirely secure, and {Application.ORG_DISPLAY_NAME} cannot guarantee the privacy of these communications.</li>
         <li><b>TCPA Compliance:</b> {Application.ORG_DISPLAY_NAME} is committed to complying with the Telephone Consumer Protection Act (TCPA) and other relevant laws governing the use of telephone numbers for communication with existing customers.</li>
         <li><b>Revisions to the TCPA Consent:</b> {Application.ORG_DISPLAY_NAME} reserves the right to modify or update this TCPA consent disclaimer at any time without prior notice. Any changes will be effective immediately upon posting the updated disclaimer on our website or through other communication channels.</li>
        </ol>
        <p>For any questions or concerns regarding this TCPA consent disclaimer or to modify your communication preferences, please contact us at {Application.CONTACT_EMAIL}.</p>
        <p>By replying to this email and providing your phone number, you signify your understanding and agreement to the terms and conditions outlined in this disclaimer.</p>
    
       </div>
      </div>
     </div>
     <Footer />
    </div>
    );
    };
    
    export default Consent;